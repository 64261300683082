<template>
  <div class="game_contain hb-100 wb-100">
    <div class="game_head center wb-100 h-100 relative">
      <div class="back fz-30 t-w pointer" @click="handleBack">
        <i class="el-icon-arrow-left fz-40"></i>
        <span class="ml-17">返回</span>
      </div>
      <div class="fz-30 t-w">{{ title }}</div>
    </div>
    <div class="game_content pt-20 pb-34 pl-68 pr-37">

      <div class="contain mt-30" ref="containRef">
        <template v-if="dataList && dataList.length">
          <div class="align-center wb-100 flex-wrap">
            <div
              class="card pointer ml-10 mr-10 mb-36"
              v-for="(item, index) in dataList"
              :key="index"
              @click="gotolive(item)"
            >
              <div class="wb-100 pl-18 pr-18 pt-14 t-b fz-22">
                  {{ school_name }}
              </div>
              <div class="fz-22 wb-100 pl-18 pr-18 pb-14 t-b ">
                ({{ item.create_time  }} - {{ item.update_time  }})
              </div>
              <div class="image_box wb-100 relative">
                <img :src="back_pic" alt="" class="wb-100 hb-100" />
                <div class="play_icon">
                  <i class="el-icon-video-play"></i>
                  <!-- <img :src="imageUrl + 'live-004.png'" alt="" class="w-80 h-80"> -->
                </div>
              </div>
            </div>
          </div>
        </template>
        <el-empty
          description="暂无数据"
          v-else
          class="mt-100 pt-100"
        ></el-empty>
      </div>
    </div>
  </div>
</template>
<script>
import { imageUrl } from "../common/common";
export default {
  data() {
    return {
      dataList: [],
      imageUrl,
      title: "",
      school_live_id: '',
      back_pic: '',
      school_name: '',
    };
  },
  created() {
    this.getQuery();
    this.getList();
  },
  methods: {
    gotolive(item) {
        this.$router.push({
          path: "/videos",
          query:{
            title: this.title
          }
        });
        window.localStorage.setItem('video_url', item.video_url)
    },

    handleBack() {
      this.$router.go(-1);
    },
    getQuery() {
      const { id, title, school_name } = this.$route.query;
      this.school_live_id = id;
      this.title = title
      this.school_name = school_name

      this.back_pic = window.localStorage.getItem('back_pic') || ''
    },
    async getList() {
      const {
        school_live_id
      } = this;
      const params = {
        school_live_id
      };
      const { data } = await this.$get("/match/school/playbackList", params);
      this.dataList = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.h-200 {
  height: 200px;
}
.back {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.game_contain {
  background-color: #f5f6fa;
  .game_head {
    background-color: #212526;
  }
  .LogOut {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .mr-200 {
    margin-right: 200px;
  }

  .action {
    color: #226bff;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 30px;
      height: 6px;
      background-color: #226bff;
      border-radius: 3px;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .contain {
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .card {
    width: 418px;
    min-height: 272px;
    background: #ffffff;
    box-shadow: 4px 16px 30px 1px rgba(102, 169, 255, 0.4);
    .image_box {
      height: 217px;
    }
  }

  .status_box {
    height: 37px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .box1 {
    background-color: #fd1041;
  }

  .box2 {
    background-color: #226bff;
  }
}

.btn {
  width: 570px;
  background-color: #fd1041;
}

.play_icon {
  font-size: 90px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #ffffff;
}
</style>
